import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import MailchimpSubscribe from 'react-mailchimp-subscribe';
import cx from 'classnames';
import get from 'utils/get';
import slugify from 'utils/slugify';

import { Button, TextField } from 'components/base';

class EmailSignup extends Component {
  state = {
    emailAddress: ''
  };

  inputRef = createRef();
  buttonRef = createRef();

  componentDidMount() {
    if (!!this.inputRef.current) {
      this.inputRef.current.addEventListener('keyup', this.handleEnterPress);
    }
  }

  componentWillUnmount() {
    this.inputRef.current.removeEventListener('keyup', this.handleEnterPress);
  }

  handleEnterPress = (e) => {
    if (e.keyCode === 13) {
      if (!!this.buttonRef.current) {
        this.buttonRef.current.click();
      }
    }
  };

  render() {
    const variantIsSecondary =
      get(this, 'props.variant', 'primary') === 'secondary';
    const fields = get(this, 'props.block.fields', {});
    const contentColor = variantIsSecondary
      ? get(this, 'props.contentColor', 'Gray')
      : slugify(get(fields, 'contentTextColor', 'White'));
    const url = get(this, 'props.url', process.env.REACT_APP_MAILCHIMP_URL);
    const text = get(
      fields,
      'text',
      'subscribe to our newsletter, sent sparingly'
    );
    const renderInFooter = get(this, 'props.renderInFooter', false);

    return (
      <div
        style={{
            backgroundColor: renderInFooter ? 'transparent' : variantIsSecondary
              ? 'transparent'
              : get(fields, 'backgroundHexColor', '#C6C5C1')
        }}
        className={cx(`EmailSignup w100 flex items-center justify-center`, {
          'px1_5 md:px4 py5': !variantIsSecondary
        })}
      >
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => {
            let error = status === 'error';
            let success = status === 'success';

            if (typeof message === 'string') {
              if (message.indexOf('subscribed') > -1) {
                success = true;
                error = false;
              }
            }

            return (
              <div
                className={cx(
                  'EmailSignup w100 single-column-content-width-sm flex flex-col items-center',
                  {
                    mt2: variantIsSecondary
                  }
                )}
              >
                <div className="w100 flex flex-col md:flex-row jusity-center items-center">
                  <TextField
                    inputRef={this.inputRef}
                    value={this.state.emailAddress}
                    onChange={(value) => this.setState({ emailAddress: value })}
                    placeholder="email"
                    color={`${contentColor}`}
                    className={cx('EmailSignup__input w100 md:mr_5', {
                      'EmailSignup--secondary-input': variantIsSecondary
                    })}
                    label="Email"
                  />
                  <div className="relative w100 md:none py1_5 flex justify-center items-center">
                    <p
                      className={cx(
                        `EmailSignup__info-text absolute color-red text-center`,
                        {
                          'EmailSignup__info-text--inactive': !error,
                          detail: !variantIsSecondary,
                          'EmailSignup--secondary-detail': variantIsSecondary
                        }
                      )}
                    >
                      something went wrong, please try again
                    </p>
                    <p
                      className={cx(
                        `EmailSignup__info-text absolute color-${contentColor} text-center`,
                        {
                          'EmailSignup__info-text--inactive': !success,
                          detail: !variantIsSecondary,
                          'EmailSignup--secondary-detail': variantIsSecondary
                        }
                      )}
                    >
                      successfully submitted your email
                    </p>
                    {!variantIsSecondary && (
                      <p
                        className={cx(
                          `EmailSignup__info-text absolute color-${contentColor} detail text-center`,
                          {
                            'EmailSignup__info-text--inactive': error || success
                          }
                        )}
                      >
                        {text}
                      </p>
                    )}
                  </div>
                  <Button
                    ariaLabel="submit email"
                    buttonRef={this.buttonRef}
                    onClick={() => {
                      subscribe({ EMAIL: this.state.emailAddress });
                      this.setState({ emailAddress: '' });
                    }}
                    className="EmailSignup__button w100 my1 ml1 mr1 md:mr0 flex items-center justify-center"
                    label={status === 'sending' ? 'loading' : 'submit'}
                    variant="primary-small"
                    color={`transparent-${contentColor}-border--responsive-invert`}
                  />
                </div>
                <div className="relative w100 none md:flex py1_5 flex-col justify-center items-center">
                  <p
                    className={cx(
                      `EmailSignup__info-text absolute color-red text-center`,
                      {
                        'EmailSignup__info-text--inactive': !error,
                        detail: !variantIsSecondary,
                        'EmailSignup--secondary-detail': variantIsSecondary
                      }
                    )}
                  >
                    something went wrong, please try again
                  </p>
                  <p
                    className={cx(
                      `EmailSignup__info-text absolute color-${contentColor} text-center`,
                      {
                        'EmailSignup__info-text--inactive': !success,
                        detail: !variantIsSecondary,
                        'EmailSignup--secondary-detail': variantIsSecondary
                      }
                    )}
                  >
                    successfully submitted your email
                  </p>
                  {!variantIsSecondary && (
                    <p
                      className={cx(
                        `EmailSignup__info-text absolute color-${contentColor} detail text-center`,
                        {
                          'EmailSignup__info-text--inactive': error || success
                        }
                      )}
                    >
                      {text}
                    </p>
                  )}
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

EmailSignup.propTypes = {
  block: PropTypes.shape({
    fields: PropTypes.shape({
      backgroundColor: PropTypes.string,
      contentTextColor: PropTypes.string,
      text: PropTypes.string
    })
  }),
  variant: PropTypes.string,
  url: PropTypes.string,
  contentColor: PropTypes.string,
  renderInFooter: PropTypes.bool
};

EmailSignup.defaultProps = {
  block: {
    fields: {
      backgroundColor: 'Gray',
      contentTextColor: 'White',
      text: 'subscribe to our newsletter, sent sparingly'
    }
  },
  variant: 'primary',
  url: process.env.REACT_APP_MAILCHIMP_URL,
  contentColor: 'black'
};

export default EmailSignup;
