import { Button } from 'components/base';
import React, { FC } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { AppState } from 'state/types';

import cx from 'classnames';

type PropsFromRedux = ConnectedProps<typeof connector>;

type SiteBannerProps = PropsFromRedux & {
  text: string;
  link: string;
  fontColor: string;
  classNames?: string;
};

const SiteBanner: FC<SiteBannerProps> = ({
  location,
  text,
  link,
  fontColor,
  classNames,
  modalPageIsOpen
}) => {
  const { pathname } = location;
  if (modalPageIsOpen) return null;
  if (pathname.startsWith('/shop/products/light-phone-ii')) return null;
  return (
    <div
      className={cx(
        'site-wide-banner',
        fontColor === 'white'
          ? 'color-white-opacity-02'
          : 'color-gray-opacity-02'
      )}
    >
      <Button
        className={cx('events-all', classNames)}
        font="nav"
        fontColor={fontColor}
        to={link}
        ariaLabel={`navigate to: ${link}`}
      >
        <p className="site-wide-banner-label">{text}</p>
      </Button>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  location: state.router.location,
  modalPageIsOpen: state.modalPage?.modalPageIsOpen
});

const connector = connect(mapStateToProps);

export default connector(SiteBanner);
