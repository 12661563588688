import * as Types from '../../lib/types';

export const AccordionBlockData_1: Types.AccordionBlock = {
  _type: 'accordionBlock',
  _id: 'accordionBlock_1',
  darkMode: true,
  title:
    'The Light operating system, LightOS, is built around intentionality & privacy.',
  backgroundMedia: {
    desktop: {
      _type: 'image',
      url: '//images.ctfassets.net/tbduj203nkkk/4mbLCFYcV8erNgQXRciHw4/aef021317a1263b8ac93daeee66ead9a/static-clouds.jpg',
      alt: 'Clouds'
    }
  },
  layout: 'layout-a',
  accordionItems: [
    {
      _type: 'accordionItem',
      _id: 'accordionItem_1',
      title: 'It’s a phone.',
      body: 'At its core, it is a simple communication device for staying in touch with those that matter most.',
      image: {
        _type: 'image',
        url: '//images.ctfassets.net/tbduj203nkkk/2hKd1OcV7b23SKMHYjwWMP/f4aef1490bd2bbbcc8486b0e132fd63d/01.PhoneAnimation.gif',
        alt: 'GIF showing the Light Phone III being used for a phone call and texting.'
      }
    },
    {
      _type: 'accordionItem',
      _id: 'accordionItem_2',
      title: 'Just the tools you’ll need.',
      body: 'LightOS is built around a customizable menu of tools managed from the dashboard website.',
      image: {
        _type: 'image',
        url: '//images.ctfassets.net/tbduj203nkkk/1883jvB2N4MbXEIsWMv03m/c2bba144704e26bdbc080438932fac52/02.ToolboxAnimation.gif',
        alt: 'GIF showing the toolbox feature on the Light Phone III.'
      }
    },
    {
      _type: 'accordionItem',
      _id: 'accordionItem_3',
      title: 'Find your way around the real world.',
      body: 'With tools like directions, LightOS brings the most useful aspects of technology without endless feeds of distraction.',
      image: {
        _type: 'image',
        url: '//images.ctfassets.net/tbduj203nkkk/6g7ANVTb8SvRpRTQw7BsKQ/c4463f36f636d47ff1b12661e3b815dc/03.Directions.png',
        alt: 'Image showing the Light Phone III being used for directions.'
      }
    },
    {
      _type: 'accordionItem',
      _id: 'accordionItem_4',
      title: 'Be intentional, you’re in control.',
      body: 'Every tool is designed for very intentional utility. No getting lost in a scroll hole.',
      image: {
        _type: 'image',
        url: '//images.ctfassets.net/tbduj203nkkk/79aIlFvjmmulz2U5BcTrfW/efd89ce40d34f8bc461c48986b597271/04.ToolsAssorted.gif',
        alt: 'GIF showing the assorted tools on the Light Phone III.'
      }
    }
  ],
  cta: {
    _type: 'buttonLink',
    label: 'Read more about how it works',
    link: {
      _type: 'link',
      url: '/lightiii',
      ariaLabel: 'Learn more about the Light Phone III'
    },
    variant: 'secondary-darkmode'
  },
  bottomSpacer: true
};

export const AccordionBlockData_2: Types.AccordionBlock = {
  _type: 'accordionBlock',
  _id: 'accordionBlock_2',
  darkMode: true,
  title: 'Going light can be whatever you’d like it to be.',
  backgroundMedia: {
    desktop: {
      _type: 'image',
      url: '//images.ctfassets.net/tbduj203nkkk/1AoNQFwCujgoUKXlSmwc5G/ef6256cf71cb1163d80bea1df201bd7d/son_father.jpeg',
      alt: 'Son and father laughing while taking a picture of a mini motorcyle with the Light Phone III.'
    }
  },
  layout: 'layout-a',
  accordionItems: [
    {
      _type: 'accordionItem',
      _id: 'accordionItem_1',
      title: 'Stay present during precious moments.',
      body: 'What are you missing out on when you pull out your smartphone hundreds of times a day? Life is just too short.',
      backgroundMedia: {
        desktop: {
          _type: 'image',
          url: '//images.ctfassets.net/tbduj203nkkk/6vUdrj2B6oETNDikUVZ1PV/8d3fd93cea6684de2b2947145fcdb4c1/01-desktop.jpg',
          alt: 'Son and father laughing while taking a picture of a mini motorcyle with the Light Phone III.'
        },
        mobile: {
          _type: 'image',
          url: '//images.ctfassets.net/tbduj203nkkk/206J0kZhnnOZ4VtZcJxAze/f171ebb99642711bf8b1eb6d44b60d7f/01-mobile.jpg',
          alt: 'Son and father laughing while taking a picture of a mini motorcyle with the Light Phone III.'
        }
      }
    },
    {
      _type: 'accordionItem',
      _id: 'accordionItem_2',
      title: 'Take your passions seriously.',
      body: 'Going light gives you space you need to focus on what really moves and inspires you.',
      backgroundMedia: {
        desktop: {
          _type: 'image',
          url: '//images.ctfassets.net/tbduj203nkkk/3ORFAsJCiQaxzIFj7kwNsv/f8844d1ba21d2f01c2d8614e2de11bb7/02-desktop.jpg',
          alt: 'Light Phone II next to a typewriter.'
        },
        mobile: {
          _type: 'image',
          url: '//images.ctfassets.net/tbduj203nkkk/46A2E9QzmcmThwX1Qv3ml1/0fbbb66e3362b1edd98e7c3d0109b6e9/02-mobile.jpg',
          alt: 'Light Phone II next to a typewriter.'
        }
      }
    },
    {
      _type: 'accordionItem',
      _id: 'accordionItem_3',
      title: 'A breath of fresh air.',
      body: 'It’s so refreshing to break free from your smartphone habits and the perpetual feeling of missing out and needing to check and re-check.',
      backgroundMedia: {
        desktop: {
          _type: 'image',
          url: '//images.ctfassets.net/tbduj203nkkk/1xIZJSBfWY292GiM73es2c/ac57a5f667541a37a450234b13ef5185/03-desktop.jpg',
          alt: 'Light Phone II on top of a stack of books.'
        },
        mobile: {
          _type: 'image',
          url: '//images.ctfassets.net/tbduj203nkkk/3rj8QwBT9SjRLDZtB2afOI/2aeca43438b7eb472be26683da6a9b10/03-mobile.jpg',
          alt: 'Light Phone II on top of a stack of books.'
        }
      }
    }
  ]
};
