import React, { FC } from 'react';

import cx from 'classnames';

import * as Types from '../lib/types';

export const Play: FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 26 26"
    fill="none"
    role="img"
    aria-label="Play"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.092041" width="25" height="26" rx="12.5" fill="white" />
    <path d="M18.092 13L9.09204 8V18L18.092 13Z" fill="#555555" />
  </svg>
);

export const Logo: FC<{ className?: string; color?: string }> = ({
  className,
  color = 'color-black'
}) => (
  <svg
    className={className}
    viewBox="0 0 60 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M11.0391 21.5534H13.7848L15.6205 8.34473H12.8933L11.0391 21.5534Z"
        className={color}
        fill="currentColor"
      />
      <path
        d="M56.9938 8.34206L57.711 3.27539H54.9838L54.2667 8.34206H52.1924L51.8953 10.5855H54.0224L52.4838 21.5508H55.211L56.7324 10.6565H59.6681L59.9996 8.34206H56.9938Z"
        className={color}
        fill="currentColor"
      />
      <path
        d="M44.7023 7.88696C43.898 7.84058 43.0794 7.96377 42.2451 8.25797C41.4123 8.55217 40.7851 8.92319 40.3651 9.37101L41.6023 0.5H38.9094L35.9766 21.5536H38.6866L39.7351 13.9435C39.8751 12.9319 40.2708 12.0957 40.9237 11.4362C41.6351 10.7188 42.5208 10.371 43.5808 10.3942C44.9323 10.4174 45.6094 11.2536 45.6094 12.9014C45.6094 13.1841 45.5866 13.4899 45.5394 13.8203L44.4551 21.5536H47.2523L48.4237 13.0783C48.4823 12.6304 48.5108 12.213 48.5108 11.8246C48.5108 9.35217 47.2409 8.04058 44.6994 7.88696H44.7023Z"
        className={color}
        fill="currentColor"
      />
      <path
        d="M30.2035 9.81558C29.1192 8.57934 27.7149 7.97355 25.9892 7.99673C24.0192 8.01992 22.3121 8.89673 20.8664 10.6272C19.4907 12.275 18.8035 14.1112 18.8035 16.1359C18.8035 17.7721 19.2635 19.1489 20.1849 20.2677C21.1521 21.4446 22.4164 22.0518 23.9792 22.0866C24.8764 22.1098 25.7921 21.9446 26.7249 21.5924C27.5992 21.2504 28.2635 20.8388 28.7178 20.3562L28.4735 22.033C28.2407 23.6214 27.7149 24.8576 26.8992 25.7417C26.0478 26.6837 24.9764 27.1127 23.6821 27.0301C22.7964 26.9706 22.1492 26.788 21.7407 26.4822C21.2278 26.0938 20.9535 25.417 20.9192 24.4518H18.1564C18.1221 24.6634 18.1035 24.875 18.1035 25.088C18.1035 26.3706 18.6278 27.4185 19.6778 28.2315C20.6221 28.9735 21.8164 29.3909 23.2621 29.4851C25.1264 29.6141 26.7992 29.0677 28.2807 27.8431C29.8192 26.5721 30.7335 24.9054 31.0249 22.846L33.0364 8.34891H30.3964L30.2035 9.81413V9.81558ZM28.3678 18.0083C27.3764 19.1504 26.1878 19.7098 24.8007 19.6851C23.7978 19.6735 22.9935 19.2793 22.3878 18.5025C21.8049 17.7721 21.5135 16.8721 21.5135 15.8011C21.5135 14.4475 21.9564 13.2286 22.8421 12.146C23.7864 10.9924 24.9164 10.4272 26.2335 10.4504C27.3878 10.4735 28.2792 10.8562 28.9092 11.5982C29.5035 12.2808 29.8007 13.1634 29.8007 14.2475C29.8007 15.6605 29.3221 16.9141 28.3664 18.0083H28.3678Z"
        className={color}
        fill="currentColor"
      />
      <path
        d="M15.0785 4.00112C15.9945 4.00112 16.7371 3.25103 16.7371 2.32575C16.7371 1.40048 15.9945 0.650391 15.0785 0.650391C14.1625 0.650391 13.4199 1.40048 13.4199 2.32575C13.4199 3.25103 14.1625 4.00112 15.0785 4.00112Z"
        className={color}
        fill="currentColor"
      />
      <path
        d="M3.20857 18.7754H0.414286L0 21.5652H3.00571L3.00714 21.5536H5.61571L8.54857 0.5H5.76857L3.20857 18.7754Z"
        className={color}
        fill="currentColor"
      />
    </g>
  </svg>
);

export const SVGMap: FC<{
  icon: Types.Icon;
  className?: string;
  color?: string;
}> = ({ icon, className, color }) => {
  switch (icon) {
    case 'play':
      return <Play className={cx(className)} />;
    case 'logo':
      return <Logo className={cx(className)} color={color} />;
    default:
      return null;
  }
};
