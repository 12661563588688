import { DARK_THEME_ON, DARK_THEME_OFF } from "state/actions/ui/darkThemeActions";

const initialState = {
  isDarkMode: false
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case DARK_THEME_ON:
    case DARK_THEME_OFF:
      return {
        ...state,
        isDarkMode: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
