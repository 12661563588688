/*
 * It's important that these handles don't change
 * in shopify!
 */
export const LIGHT_PHONE_LIGHT_HANDLE = 'light-phone-ii-light-gray';
export const LIGHT_PHONE_BLACK_HANDLE = 'light-phone-ii-black';
export const LIGHT_PHONE_III_HANDLE = 'light-phone-iii';
export const PROTECTIVE_CASE_LIGHT_HANDLE = 'protective-case';
export const PROTECTIVE_CASE_BLACK_HANDLE = 'protective-case-1';
export const LIGHT_PROTECT_PLAN_HANDLE = 'light-protect-plan';
