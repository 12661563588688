import React, { FC, useEffect, useState } from 'react';
import { useInView } from 'react-hook-inview';
import { useMatchMedia } from 'hooks/useMatchMedia';

import cx from 'classnames';
import BackgroundMedia from 'components/BackgroundMedia';

import * as Types from '../../lib/types';

type TestimonialBlockProps = {
  block: Types.TestimonialBlock;
};

const Testimonial: FC<{
  testimonial: Types.Testimonial;
  numberOfTestimonials: number;
  isActive: boolean;
  activeIndex: number;
  index: number;
}> = ({ testimonial, numberOfTestimonials, isActive, activeIndex, index }) => {
  const [isPrev, setIsPrev] = useState(false);

  const maxNumberOfTestimonialPositions = 4;

  useEffect(() => {
    if (index === 0 && activeIndex === index + 1) {
      setIsPrev(true);
    } else if (index === numberOfTestimonials - 1 && activeIndex === 0) {
      setIsPrev(true);
    } else if (index === activeIndex - 1) {
      setIsPrev(true);
    } else setIsPrev(false);
  }, [activeIndex, index, numberOfTestimonials]);

  return (
    <div
      className={cx(
        'testimonial',
        `testimonialPosition testimonialPosition--${
          (index % maxNumberOfTestimonialPositions) + 1
        }`,
        isActive && 'active-testimonial',
        isPrev && 'prev-testimonial',
        !isActive && !isPrev && 'inactive-testimonial'
      )}
    >
      <div className="testimonial-inner-wrapper">
        <div className="disc" />
        <div>
          <p className="testimonial__quote">“{testimonial.quote}”</p>
          {testimonial.attribution && (
            <p className="testimonial__attribution">
              {testimonial.attribution}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const TestimonialBlock: FC<TestimonialBlockProps> = ({ block }) => {
  const { backgroundMedia, testimonials } = block;
  const [active, setActive] = useState(0);
  const isMobile = useMatchMedia('(max-width: 749px)');

  const [ref, isVisible] = useInView({
    rootMargin: '0px 0px -50% 0px'
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActive((prevActive) => {
        const nextIndex = (prevActive + 1) % testimonials.length;
        return nextIndex;
      });
    }, 8000);

    return () => clearInterval(intervalId);
  }, [testimonials.length]);

  return (
    <div
      ref={ref}
      className={cx('relative', {
        'bg-color-lightest-gray': !backgroundMedia
      })}
    >
      {backgroundMedia && (
        <div
          className={cx(
            'relative background-media',
            isVisible ? 'opacity-1' : 'opacity-0'
          )}
        >
          <div className="absolute z-above t0 l0 w100 h100 bg-color-black-opacity-02" />
          <BackgroundMedia
            media={
              isMobile && backgroundMedia.mobile
                ? backgroundMedia.mobile
                : backgroundMedia.desktop
            }
            videoWidth="100%"
            videoHeight="100%"
          />
        </div>
      )}
      <div
        className={cx(
          'testimonial-block',
          backgroundMedia ? 'min-vh100' : 'v100'
        )}
      >
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={index}
            testimonial={testimonial}
            numberOfTestimonials={testimonials.length}
            isActive={index === active}
            activeIndex={active}
            index={index}
          />
        ))}
      </div>
      <div className="content-block-spacer" />
    </div>
  );
};

export default TestimonialBlock;
