import React, { FC } from 'react';
import { Button, Image } from 'components/base';
import { getSrcSet } from 'utils/getSrcSet';

import cx from 'classnames';
import contentfulImgUtil from 'utils/contentfulImgUtil';

import * as Types from '../../lib/types';

type DetailsBlockProps = {
  block: Types.DetailsBlock;
};

const DetailsBlock: FC<DetailsBlockProps> = ({ block }) => {
  const { darkMode, details, backgroundMedia } = block;
  return (
    <div
      className={cx(
        'details-block',
        !backgroundMedia && 'bg-color-lightest-gray'
      )}
    >
      <div className="details-container">
        {/* Detail Group */}
        {details.map((detailGroup) => (
          <div key={detailGroup._id} className="detailGroup">
            {/* Detail */}
            {detailGroup.details.map((detail) => (
              <div key={detail._id} className="detail">
                {/* Title */}
                {detail.titleLink ? (
                  <h2 className="detail-title">
                    <span>{detail.title}</span>
                    <Button
                      to={detail.titleLink.url}
                      wrapperClassName="block"
                      className="detail-title-link"
                      variant="text"
                      fontColor={darkMode ? 'white' : 'gray'}
                      ariaLabel={detail.titleLink.ariaLabel}
                    >
                      <span className="detail-title">
                        {detail.titleLink.label}
                      </span>
                    </Button>
                  </h2>
                ) : (
                  <h2 className="detail-title">{detail.title}</h2>
                )}

                <div className="detail-content">
                  {/* Body */}
                  {detail.body && <p>{detail.body}</p>}

                  {/* Image */}
                  {detail.image && (
                    <Image
                      src={contentfulImgUtil(detail.image.url)}
                      sizes="15vw"
                      srcSet={getSrcSet(detail.image.url)}
                      alt={detail.image.alt}
                      className="detail-image"
                    />
                  )}

                  {/* List */}
                  {detail.list && (
                    <ul
                      className={cx(
                        'detail-list',
                        detail.list.length >= 3 && 'detail-list-fadeOut'
                      )}
                      tabIndex={-1}
                    >
                      {detail.list.length >= 3 && (
                        <div
                          className="detail-list-overlay"
                          aria-hidden={true}
                        />
                      )}
                      {detail.list.map((item, index) => (
                        <Button
                          key={index}
                          to={detail.titleLink?.url}
                          variant="text"
                          fontColor={darkMode ? 'white' : 'gray'}
                          ariaLabel={detail.titleLink?.ariaLabel}
                        >
                          <li className="detail-item">{item}</li>
                        </Button>
                      ))}
                    </ul>
                  )}

                  {/* Links */}
                  {detail.links && (
                    <div className="detail-links">
                      {detail.links.map((link, index) => (
                        <Button
                          key={index}
                          to={link.link.url}
                          className="detail-link"
                          variant="text"
                          font="akkurat"
                          fontColor={darkMode ? 'white' : 'gray'}
                          label={link.label}
                          ariaLabel={link.link.ariaLabel}
                        />
                      ))}
                    </div>
                  )}

                  {/* CTAs */}
                  {detail.ctas && (
                    <div className="detail-ctas">
                      {detail.ctas.map((cta, index) => (
                        <Button
                          key={index}
                          to={cta.link.url}
                          variant="secondary-v2"
                          label={cta.label}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailsBlock;
