import React, { FC } from 'react';
import { TestimonialBlockData } from 'containers/HomepageContainers/TestimonialBlockContainer';
import {
  TextBlockData_1,
  TextBlockData_2,
  TextBlockData_3,
  TextBlockData_4,
  TextBlockData_5
} from 'containers/HomepageContainers/TextBlockContainer';
import {
  AccordionBlockData_1,
  AccordionBlockData_2
} from 'containers/HomepageContainers/AccordionBlockContainer';
import { ProductBlockData } from 'containers/HomepageContainers/ProductBlockContainer';
import { DetailsBlockData } from 'containers/HomepageContainers/DetailsBlockContainer';
import { SingleImageBlockData } from 'containers/HomepageContainers/SingleImageBlockContainer';

import ContentBlocks from 'components/ContentBlocks';

import * as Types from '../lib/types';

// TODO: Add SEO to homepage

const HomepageView: FC = () => {
  const homepage: Types.HomepageContent = {
    _type: 'homepage',
    blocks: [
      TestimonialBlockData,
      TextBlockData_1,
      TextBlockData_2,
      TextBlockData_3,
      AccordionBlockData_1,
      AccordionBlockData_2,
      ProductBlockData,
      TextBlockData_4,
      TextBlockData_5,
      DetailsBlockData,
      SingleImageBlockData
    ]
  };

  const { blocks } = homepage;

  return (
    <div className="HomepageView">
      <h1 className="none">Homepage</h1>
      {/* Remove ContentBlocks until blocks have been created */}
      <ContentBlocks contentBlocks={blocks} />
    </div>
  );
};

export default HomepageView;
