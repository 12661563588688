import * as Types from '../../lib/types';

export const TextBlockData_1: Types.TextBlock = {
  _type: 'textBlock',
  _id: 'textBlock_1',
  darkMode: true,
  title:
    'Light Phones are simple devices with quality tools, designed to be used as little as possible.',
  backgroundMedia: {
    desktop: {
      _type: 'video',
      url: 'https://videos.ctfassets.net/tbduj203nkkk/1XOclnkjpEddQPo4ArOLu5/39f632ca8caf248a7bbd6f20837f1e66/Both_Phones.v1.mp4',
      alt: 'Video showing how the Light Phone III and Light Phone II let you live your best life.',
      posterImage: {
        _type: 'image',
        url: 'https://images.ctfassets.net/tbduj203nkkk/3HY22b6OodrDxvZhxg6cYF/911ea85f161c692a553f0fe10c470b90/both-phones-posterimage.png',
        alt: 'Person taking a picture with the Light Phone III.'
      }
    }
  }
};

export const TextBlockData_2: Types.TextBlock = {
  _type: 'textBlock',
  _id: 'textBlock_2',
  darkMode: true,
  title:
    'The value of the Light Phone is not necessarily in any specific feature, but rather the experience we call going light. In our hyper-connected lives, going light is a profound shift.',
  backgroundMedia: {
    desktop: {
      _type: 'video',
      url: '//videos.ctfassets.net/tbduj203nkkk/41x6yCRCxVwoOxlNI3JYFi/d77912a5c4168d9bfb1b962f0a0dede0/GoingLight.v1.mp4',
      alt: 'Video of the Light Phone OS',
      posterImage: {
        _type: 'image',
        url: '//images.ctfassets.net/tbduj203nkkk/4nzslg4X8rXGzHPUqH5z2o/a0dff214711bd9b16766c94a85212c84/Screenshot_2025-01-13_at_11.48.09_AM.png',
        alt: 'Light Phone OS'
      }
    }
  },
  foregroundMedia: {
    _type: 'foregroundMedia',
    media: {
      _type: 'image',
      url: 'https://images.ctfassets.net/tbduj203nkkk/6Jo285f3K0Jnn0VDA6nMmX/b71221434b7ca24e1ce67f5a096872d8/InterfaceOverlay_0115.png',
      alt: 'Light Phone OS'
    },
    video: {
      _type: 'video',
      url: 'https://vimeo.com/954143759',
      alt: 'Video of the Light Phone OS',
      posterImage: {
        _type: 'image',
        url: '//images.ctfassets.net/tbduj203nkkk/20XbL3MDcyMojphKf83wzD/c5ac92367d793327b7e7d80cb1e8ba4d/Screenshot_2025-01-13_at_11.50.05_AM.png',
        alt: 'Light Phone OS'
      },
      caption: 'What is a Light Phone?'
    }
  }
};

export const TextBlockData_3: Types.TextBlock = {
  _type: 'textBlock',
  _id: 'textBlock_3',
  darkMode: true,
  applyScreenBlend: true,
  title:
    'Light is an alternative to the tech monopolies that are fighting aggressively for our time & attention. We make tools that serve you, not the other way around.',
  backgroundMedia: {
    desktop: {
      _type: 'image',
      url: '//images.ctfassets.net/tbduj203nkkk/6hkHam9NcY3rkdMr96JNRP/beb8818dd120ba7ce56e7e5fd78a70d8/stars.png',
      alt: 'Illustration of stars'
    }
  },
  foregroundMedia: {
    _type: 'foregroundMedia',
    media: {
      _type: 'video',
      url: '//videos.ctfassets.net/tbduj203nkkk/skLO8fdhcQSTyifcAbEiz/d9c8b03afdfed1af1df9e2f887755c0d/scrolling-smooth.mp4',
      alt: 'Looping video illustration of an eye with the reflection of someone scrolling on a phone',
      posterImage: {
        _type: 'image',
        url: '//images.ctfassets.net/tbduj203nkkk/0o3ettRU9atYgNpiUQ4PK/ce47e4f03264c8e73990c4e54474723f/Screenshot_2025-01-13_at_11.51.56_AM.png',
        alt: 'Illustration of an eye'
      }
    },
    video: {
      _type: 'video',
      url: 'https://vimeo.com/1028897635',
      alt: 'Video about the addictive nature of smartphones',
      posterImage: {
        _type: 'image',
        url: '//images.ctfassets.net/tbduj203nkkk/0o3ettRU9atYgNpiUQ4PK/ce47e4f03264c8e73990c4e54474723f/Screenshot_2025-01-13_at_11.51.56_AM.png',
        alt: 'Illustration of an eye'
      },
      caption: 'A radically different phone'
    }
  }
};

export const TextBlockData_4: Types.TextBlock = {
  _type: 'textBlock',
  _id: 'textBlock_4',
  darkMode: false,
  title: 'Thinking about getting a Light Phone?',
  foregroundMedia: {
    _type: 'foregroundMedia',
    media: {
      _type: 'image',
      url: 'https://images.ctfassets.net/tbduj203nkkk/1VVu4IDPQJ5UCmTIx6246y/1d5c557088ba0d04513e5f971825b3ca/light-phone-users-0115.jpg',
      alt: 'Composite image of light phone users.'
    },
    video: {
      _type: 'video',
      url: 'https://vimeo.com/1020289535',
      alt: 'Video testimonials of real Light Phone users',
      posterImage: {
        _type: 'image',
        url: 'https://images.ctfassets.net/tbduj203nkkk/1VVu4IDPQJ5UCmTIx6246y/1d5c557088ba0d04513e5f971825b3ca/light-phone-users-0115.jpg',
        alt: 'Composite image of light phone users.'
      },
      caption: 'Hear from everyday Light Phone users'
    }
  }
};

export const TextBlockData_5: Types.TextBlock = {
  _type: 'textBlock',
  _id: 'textBlock_5',
  darkMode: false,
  title:
    'Over 10 years, more than 100,000 people have gone light. It’s a small but mighty movement, and it’s only grown stronger.',
  foregroundMedia: {
    _type: 'foregroundMedia',
    media: {
      _type: 'image',
      url: '//images.ctfassets.net/tbduj203nkkk/uO3Z2Wbkq6JftngMAbcxC/03a0f99ecd0c1282ff354b97b432036d/kai_joe.png',
      alt: 'Kai and Joe in the Light Phone factory.'
    },
    video: {
      _type: 'video',
      url: 'https://vimeo.com/1014506785',
      alt: 'Video of Kai and Joe in the Light Phone factory.',
      posterImage: {
        _type: 'image',
        url: '//images.ctfassets.net/tbduj203nkkk/uO3Z2Wbkq6JftngMAbcxC/03a0f99ecd0c1282ff354b97b432036d/kai_joe.png',
        alt: 'Kai and Joe in the Light Phone factory.'
      },
      caption: 'The little phone company'
    }
  }
};
