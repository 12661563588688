import gql from 'graphql-tag';
import { productFragment } from './sharedFragments';
import {
  LIGHT_PHONE_BLACK_HANDLE,
  LIGHT_PHONE_LIGHT_HANDLE,
  LIGHT_PHONE_III_HANDLE,
  LIGHT_PROTECT_PLAN_HANDLE,
  PROTECTIVE_CASE_BLACK_HANDLE,
  PROTECTIVE_CASE_LIGHT_HANDLE
} from 'constants/Shopify';

export const FETCH_PHONE_FLOW_PRODUCTS_QUERY = gql`
  query PhoneFlowProductsQuery {
    lightPhoneLight: product(handle: "${LIGHT_PHONE_LIGHT_HANDLE}") {
      ...ProductFragment
    }

    lightPhoneDark: product(handle: "${LIGHT_PHONE_BLACK_HANDLE}") {
      ...ProductFragment
    }

    lightPhoneIII: product(handle: "${LIGHT_PHONE_III_HANDLE}") {
      ...ProductFragment
    }

    screenProtector: product(handle: "screen-protector") {
      ...ProductFragment
    }

    protectiveCaseBlack: product(handle: "${PROTECTIVE_CASE_BLACK_HANDLE}") {
      ...ProductFragment
    }

    protectiveCaseLight: product(handle: "${PROTECTIVE_CASE_LIGHT_HANDLE}") {
      ...ProductFragment
    }

    carbonOffset: product(handle: "carbon-offset") {
      ...ProductFragment
    }

    lightUsSim: product(handle: "light-us-sim-card") {
      ...ProductFragment
    }

    deviceInsurance: product(handle: "${LIGHT_PROTECT_PLAN_HANDLE}") {
      ...ProductFragment,
      sellingPlanGroups(first: 10) {
        __typename
        edges {
          __typename
          cursor,
          node {
            __typename
            appName
            name
            options {
              name
              values
            }
            sellingPlans(first: 10) {
              edges {
                __typename
                cursor
                node {
                  checkoutCharge {
                    type
                    value {
                      ...on MoneyV2 {
                        __typename
                        amount
                        currencyCode
                      }
                      ...on SellingPlanCheckoutChargePercentageValue {
                        __typename
                        percentage
                      }
                    }
                  }
                  description
                  id
                  name
                  options {
                    name
                    value
                  }
                  priceAdjustments {
                    adjustmentValue {
                      ...on SellingPlanFixedAmountPriceAdjustment {
                        __typename
                        adjustmentAmount {
                          __typename
                          amount
                          currencyCode
                        }
                      }
                      ...on SellingPlanFixedPriceAdjustment {
                        __typename
                        price {
                          __typename
                          amount
                          currencyCode
                        }
                      }
                      ...on SellingPlanPercentagePriceAdjustment {
                        __typename
                        adjustmentPercentage
                      }
                    }
                  }
                  recurringDeliveries
                }
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
  ${productFragment}
`;
