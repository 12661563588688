export const DARK_THEME_ON = 'DARK_THEME_ON';
export const darkThemeOn = () => {
  return {
    type: DARK_THEME_ON,
    payload: true
  };
};

export const DARK_THEME_OFF = 'DARK_THEME_OFF';
export const darkThemeOff = () => {
  return {
    type: DARK_THEME_OFF,
    payload: false
  };
};