import React, { FC } from 'react';
import { Image, Video } from 'components/base';

import cx from 'classnames';
import contentfulImgUtil from 'utils/contentfulImgUtil';

import * as Types from '../../lib/types';

type BackgroundMediaProps = {
  media: Types.Image | Types.Video;
  className?: string;
  videoWidth?: string;
  videoHeight?: string;
};

const BackgroundMedia: FC<BackgroundMediaProps> = ({
  media,
  className,
  videoWidth,
  videoHeight
}) => {
  switch (media._type) {
    case 'image':
      return (
        <Image
          bg={true}
          src={contentfulImgUtil(media.url, '1600')}
          className={cx('background-image', className)}
          style={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
      );
    case 'video':
      return (
        <Video
          video={media}
          autoPlay
          loop
          muted
          className={cx('background-video', className)}
          width={videoWidth}
          height={videoHeight}
        />
      );
    default:
      return null;
  }
};

export default BackgroundMedia;
