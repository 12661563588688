import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomepageView from 'views/HomepageView';
import GenericContainer from 'containers/GenericContainer';
import ArticleContainer from 'containers/ArticleContainer';
import BlogIndexContainer from 'containers/BlogIndexContainer';
import CompatibilityCheckContainer from 'containers/CompatibilityCheckContainer';
import ErrorPage from 'components/ErrorPage';
import FooterPageContainer from 'containers/FooterPageContainer';
import ShopContainer from 'containers/ShopContainer';
import LightPhoneFlowContainer from 'containers/LightPhoneFlowContainer';
import LightPhoneIIIFlowContainer from 'containers/LightPhoneIIIFlowContainer';

import CartView from 'views/CartView';

const Routes = ({ location, globalSettings }) => {
  return (
    <Switch location={location}>
      <Route exact path="/" component={HomepageView} />
      <Route exact path="/footer" component={FooterPageContainer} />
      <Route
        exact
        path="/compatibility-checker"
        component={CompatibilityCheckContainer}
      />
      <Route path="/products" component={LightPhoneFlowContainer} />

      {/* Shopify routes */}
      <Route path="/cart/:flow?" component={CartView} />
      <Route
        path="/shop/products/light-phone-ii-(light-gray|black)"
        exact
        // @ts-ignore
        component={LightPhoneFlowContainer}
      />
      <Route 
        path="/shop/products/light-phone-iii"
        exact 
        component={LightPhoneIIIFlowContainer} />

      <Route
        path="/shop/:collectionHandle?"
        /* Note: This component contains child routes for products */
        component={ShopContainer}
      />
      <Route
        exact
        path="/dashboard"
        component={() => {
          window.location.replace(
            'https://dashboard.thelightphone.com/login'
          );
          return null;
        }}
      />
      <Route
        exact
        path="/support"
        component={() => {
          window.location.replace('https://support.thelightphone.com');
          return null;
        }}
      />
      <Route
        exact
        path="/usermanual"
        component={() => {
          window.location.replace('https://support.thelightphone.com');
          return null;
        }}
      />
      <Route exact path="/blog" component={BlogIndexContainer} />

      <Route exact path="/:genericPageSlug" component={GenericContainer} />

      <Route exact path="/blog/:articleSlug" component={ArticleContainer} />

      <Route
        path="*"
        render={() => (
          <ErrorPage
            backgroundImage={globalSettings.errorPageBackgroundImage}
            logo={globalSettings.lightLogoWhite}
            errorMessage="sorry, page not found"
            buttonLabel="return to home page"
            buttonLink="/"
          />
        )}
      />
    </Switch>
  );
};

export default Routes;
