/**
 * Utils
 */
type NavSettings = {
  navColor: string;
  buyButtonIsActive: boolean;
  menuButtonIsActive: boolean;
};

/* Settings from Contentful */
type PageNavSettings = {
  slug: string;
  navColor?: string;
  buyButtonIsActive?: boolean;
  menuButtonIsActive?: boolean;
};

export const getNavSettings = (
  pathname: string,
  pageNavSettings?: PageNavSettings
): NavSettings => {
  /* If the current path matches the page's slug, respect its settings */
  if (pageNavSettings?.slug === pathname) {
    return {
      /* defaults */
      navColor: 'white',
      buyButtonIsActive: true,
      menuButtonIsActive: true,
      /* override with page settings */
      ...pageNavSettings
    };
  } else if (pathname === '/') {
    return {
      navColor: 'white',
      buyButtonIsActive: true,
      menuButtonIsActive: true
    };
  }
  /* Otherwise, return defaults */
  return {
    navColor: 'black',
    buyButtonIsActive: true,
    menuButtonIsActive: true
  };
  //
};
