import { Button, Image } from 'components/base';

import cx from 'classnames';
import slugify from 'utils/slugify';

import * as React from 'react';

interface ModalProps {
  children: React.ReactNode;
  backgroundColor: string;
  closeIconColor: string;
  onGoBack: () => void;
  className?: string;
  innerWrapperStyleOverrides?: string;
  title: string;
}

const Modal: React.FC<ModalProps> = ({
  backgroundColor = 'white',
  closeIconColor,
  onGoBack,
  className,
  innerWrapperStyleOverrides,
  title,
  children
}) => {
  const closeIcon =
    slugify(closeIconColor) === 'white'
      ? '/assets/images/close-icon-white.svg'
      : '/assets/images/close-icon-black.svg';

  const handleBackgroundClick = (e: React.MouseEvent) => {
    // Only close if clicking the outer wrapper directly
    if (e.target === e.currentTarget) {
      onGoBack();
    }
  };

  // Handle ESC key press
  React.useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onGoBack();
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [onGoBack]);

  return (
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      style={{ backgroundColor }}
      className={cx('ModalPageView relative z-overlay', className)}
      onClick={handleBackgroundClick}
    >
      <div
        className={cx(
          innerWrapperStyleOverrides
            ? innerWrapperStyleOverrides
            : 'relative wh100 pt3 md:pt6 pb3 md:py6 flex flex-col justify-between'
        )}
      >
        <h2 id="modal-title" className="sr-only">
          {title}
        </h2>
        <Button
          ariaLabel="Close modal"
          onClick={onGoBack}
          className="ModalPageView__close-icon absolute l0 t0 mt3 ml1_5 md:ml3 z1 fixed"
        >
          <Image src={closeIcon} alt="" aria-hidden="true" />
          <span className="sr-only">Close modal</span>
        </Button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
