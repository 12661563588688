import * as Types from '../../lib/types';

export const SingleImageBlockData: Types.SingleImageBlock = {
  _type: 'singleImageBlock',
  _id: 'single-image-block',
  darkMode: false,
  image: {
    _type: 'image',
    url: '//images.ctfassets.net/tbduj203nkkk/57JDkwjfveByyKs84GvZs7/fed016331b10bc47fc9fa44017c49a9b/MuchLoveLight_FINAL.png',
    alt: 'Illustrated text that reads "Much Love, Light".'
  }
};
