import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import cx from 'classnames';
import isExternalLink from 'utils/isExternalLink';
import generateNextPathWithParams from 'utils/generateNextPathWithParams';

const Button = ({
  wrapperClassName,
  id,
  className,
  label,
  variant,
  fontColor,
  color,
  children,
  isDisabled,
  onClick,
  type,
  to,
  newTab,
  hover,
  openInCurrentTab,
  ariaLabel,
  font,
  buttonRef,
  isSubmitting
}) => {
  const classes = cx(
    'pointer inline-flex',
    className,
    'Button',
    `Button--style-${variant}`,
    `Button--color-${color}`,
    `Button--font-color-${fontColor}`,
    `Button--hover-${hover}`,
    `Button--font-${font}`,
    {
      'Button--disabled': isDisabled,
      'Button--submitting': isSubmitting
    }
  );

  const linkIsEmail = to.includes('@') && !to.includes('/');

  const linkedComponent =
    isExternalLink(to) || newTab || linkIsEmail ? (
      <a
        id={id}
        className={cx(wrapperClassName, 'Button text-decoration-none', {
          'events-none': isDisabled
        })}
        target={
          !newTab && (openInCurrentTab || linkIsEmail) ? '_self' : '_blank'
        }
        href={linkIsEmail ? `mailto:${to}` : to}
        rel="noopener noreferrer"
        onClick={onClick}
        aria-label={ariaLabel}
      >
        <div ref={buttonRef} className={classes}>
          {children && !label ? (
            children
          ) : (
            <span className="h100 flex justify-center items-center">
              {label}
            </span>
          )}
        </div>
      </a>
    ) : (
      <Link
        id={id}
        aria-label={ariaLabel}
        className={cx(wrapperClassName, 'Button text-decoration-none', {
          'Button--color-white': color === 'white',
          'events-none': isDisabled
        })}
        to={generateNextPathWithParams(to)}
        onClick={onClick}
      >
        <div ref={buttonRef} className={classes}>
          {children && !label ? (
            children
          ) : (
            <span className="h100 flex justify-center items-center">
              {label}
            </span>
          )}
        </div>
      </Link>
    );

  const button = to ? (
    linkedComponent
  ) : (
    <button
      id={id}
      ref={buttonRef}
      aria-label={ariaLabel}
      type={type}
      onClick={onClick}
      className={classes}
    >
      <div className="h100 flex justify-center items-center">
        {children && !label ? children : label}
      </div>
    </button>
  );

  return button;
};

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  fontColor: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  to: PropTypes.string,
  openInCurrentTab: PropTypes.bool,
  hover: PropTypes.string,
  ariaLabel: PropTypes.string,
  font: PropTypes.string,
  newTab: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  isSubmitting: PropTypes.bool,
  buttonRef: PropTypes.any
};

Button.defaultProps = {
  id: '',
  className: '',
  children: null,
  label: '',
  variant: 'none',
  fontColor: 'none',
  color: 'none',
  onClick: () => {},
  isDisabled: false,
  type: 'button',
  to: '',
  openInCurrentTab: false,
  hover: 'none',
  ariaLabel: '',
  font: 'futura-pt',
  newTab: false
};

export default Button;
