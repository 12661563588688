import * as Types from '../../lib/types';

const LightIII: Types.Product = {
  _type: 'product',
  _id: 'lightiii',
  title: 'Light Phone III',
  body: 'Moves at the speed of light, now with a camera.',
  image: {
    _type: 'image',
    url: '//images.ctfassets.net/tbduj203nkkk/6jxH5zjjuQrb1dgPHXbE9t/2bbc0e0ee035cbc826e661ffd76a937f/LightPhoneIII.Product.png',
    alt: 'Product shot of the Light Phone III (front).'
  },
  ctas: [
    {
      _type: 'buttonLink',
      label: 'Learn more',
      link: {
        _type: 'link',
        url: '/lightiii',
        openInNewTab: false,
        ariaLabel: 'Learn more about the Light Phone III'
      },
      variant: 'primary'
    },
    {
      _type: 'buttonLink',
      label: 'Buy',
      link: {
        _type: 'link',
        url: '/shop/products/light-phone-iii',
        ariaLabel: 'Buy the Light Phone III',
        openInNewTab: false
      },
      variant: 'secondary'
    }
  ]
};

const LightII: Types.Product = {
  _type: 'product',
  _id: 'lightii',
  title: 'Light Phone II',
  body: 'Our lightest phone with an E-ink screen.',
  image: {
    _type: 'image',
    url: '//images.ctfassets.net/tbduj203nkkk/743zr1qRkC8POkdrR0Sekz/b52475718f73db444054e5d3ad647c91/LightPhoneIIProduct.png',
    alt: 'Product shot of the Light Phone II (front).'
  },
  ctas: [
    {
      _type: 'buttonLink',
      label: 'Learn more',
      link: {
        _type: 'link',
        url: '/lightii',
        openInNewTab: false,
        ariaLabel: 'Learn more about the Light Phone II'
      },
      variant: 'primary'
    },
    {
      _type: 'buttonLink',
      label: 'Buy',
      link: {
        _type: 'link',
        url: '/shop/products/light-phone-ii-black',
        openInNewTab: false,
        ariaLabel: 'Buy the Light Phone II'
      },
      variant: 'secondary'
    }
  ]
};

export const ProductBlockData: Types.ProductBlock = {
  _type: 'productBlock',
  _id: 'productBlock',
  darkMode: false,
  title:
    'There are two different Light Phones, depending on how light you want to go.',
  layout: 'layout-b',
  products: [LightIII, LightII]
};
