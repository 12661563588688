import React, { FC } from 'react';
import { Image } from 'components/base';
import { getSrcSet } from 'utils/getSrcSet';

import contentfulImgUtil from 'utils/contentfulImgUtil';

import * as Types from '../../lib/types';

type SingleImageBlockProps = {
  block: Types.SingleImageBlock;
};

const SingleImageBlock: FC<SingleImageBlockProps> = ({ block }) => {
  return (
    <div className="image-block bg-color-lightest-gray">
      <div className="image-block__image-wrapper">
        <Image
          src={contentfulImgUtil(block.image.url)}
          sizes="(max-width: 750px) 20vw, 13vw"
          srcSet={getSrcSet(block.image.url)}
          alt={block.image.alt}
          className="image-block__image"
        />
      </div>
    </div>
  );
};

export default SingleImageBlock;
