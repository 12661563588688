import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import cx from 'classnames';
import isExternalLink from 'utils/isExternalLink';
import generateNextPathWithParams from 'utils/generateNextPathWithParams';

// Base Button component
type SharedButtonProps = PropsWithChildren & {
  to: string;
  ariaLabel: string;
  buttonType: 'primary' | 'secondary' | 'secondary-darkmode';
  id?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  openInNewTab?: boolean;
  openInCurrentTab?: boolean;
  className?: string;
  buttonRef?: any;
};

// type PrimaryButtonProps = SharedButtonProps & {
//   label: string;
// };

// export const PrimaryButton: FC<PrimaryButtonProps> = (props) => {
//   return (
//     <BaseButton {...props}>
//       <div className={cx('PrimaryButton', props.className)}>{props.label}</div>
//     </BaseButton>
//   );
// };

export const ButtonV2: FC<SharedButtonProps> = ({
  id,
  children,
  isDisabled,
  onClick,
  to,
  openInCurrentTab,
  openInNewTab,
  ariaLabel,
  buttonType,
  buttonRef,
  className
}) => {
  const linkIsEmail = to.includes('@') && !to.includes('/');

  const linkedComponent =
    isExternalLink(to) || openInNewTab || linkIsEmail ? (
      <a
        id={id}
        className={cx('BaseButton', className, {
          PrimaryButton: buttonType === 'primary',
          SecondaryButton: buttonType === 'secondary',
          SecondaryButton__darkMode: buttonType === 'secondary-darkmode',
          'events-none': isDisabled
        })}
        target={
          !openInNewTab && (openInCurrentTab || linkIsEmail)
            ? '_self'
            : '_blank'
        }
        href={linkIsEmail ? `mailto:${to}` : to}
        rel="noopener noreferrer"
        onClick={onClick}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    ) : (
      <Link
        id={id}
        aria-label={ariaLabel}
        to={generateNextPathWithParams(to)}
        onClick={onClick}
        ref={buttonRef}
        className={cx('BaseButton', className, {
          PrimaryButton: buttonType === 'primary',
          SecondaryButton: buttonType === 'secondary',
          SecondaryButton__darkMode: buttonType === 'secondary-darkmode'
        })}
      >
        {children}
      </Link>
    );

  const button = to ? (
    linkedComponent
  ) : (
    <button
      className={cx('BaseButton', className, {
        PrimaryButton: buttonType === 'primary',
        SecondaryButton: buttonType === 'secondary',
        SecondaryButton__darkMode: buttonType === 'secondary-darkmode'
      })}
      id={id}
      ref={buttonRef}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </button>
  );

  return button;
};
