import * as Types from '../../lib/types';

const Detail_1: Types.Detail = {
  _type: 'detail',
  _id: 'detail_1',
  title: 'Things for going light. ',
  titleLink: {
    _type: 'link',
    label: 'Shop Light Phones & accessories.',
    url: '/shop',
    openInNewTab: false,
    ariaLabel: 'Shop Light Phones & accessories.'
  },
  image: {
    _type: 'image',
    url: '//images.ctfassets.net/tbduj203nkkk/2g2PiO52WwNRzIZYnVokh2/7a2482e1dc204ed2c842253ad51f65ff/BothLightPhones.Scale.png',
    alt: 'Product shot of the Light Phone III (front).'
  }
};

const Detail_2: Types.Detail = {
  _type: 'detail',
  _id: 'detail_2',
  title: 'Questions? ',
  titleLink: {
    _type: 'link',
    label: 'Read our FAQ.',
    url: '/faq',
    openInNewTab: false,
    ariaLabel: 'Read our FAQ.'
  },
  // max 3 items
  list: [
    'Can it work with my existing phone?',
    'Will this work for my carrier?',
    'Does it have Bluetooth/WiFi?'
  ]
};

const Detail_3: Types.Detail = {
  _type: 'detail',
  _id: 'detail_3',
  title: 'Get set up with service on your Light Phone.',
  links: [
    {
      _type: 'buttonLink',
      label: 'Check carrier compatibility',
      link: {
        _type: 'link',
        url: '/compatibility-checker',
        ariaLabel: 'Check carrier compatibility.'
      }
    },
    {
      _type: 'buttonLink',
      label: 'The Light Service Plan',
      link: {
        _type: 'link',
        url: '/plans',
        ariaLabel: 'Learn more about the Light Service Plan'
      }
    }
  ]
};

const Detail_4: Types.Detail = {
  _type: 'detail',
  _id: 'detail_4',
  title: 'Follow along our journey ',
  titleLink: {
    _type: 'link',
    label: 'on our blog.',
    url: '/blog',
    openInNewTab: false,
    ariaLabel: 'Read the Light blog'
  },
  links: [
    {
      _type: 'buttonLink',
      label: 'pgLang & Light Collaboration',
      link: {
        _type: 'link',
        url: '/blog/light-x-pglang-collaboration',
        ariaLabel: 'Read about the pgLang & Light Collaboration'
      }
    },
    {
      _type: 'buttonLink',
      label: 'Buxton School Goes Light',
      link: {
        _type: 'link',
        url: '/blog/buxton-school-goes-light',
        ariaLabel: 'Read about the Buxton School going Light'
      }
    }
  ]
};

const Details_1: Types.Details = {
  _type: 'details',
  _id: 'details_1',
  details: [Detail_1, Detail_2]
};

const Details_2: Types.Details = {
  _type: 'details',
  _id: 'details_2',
  details: [Detail_3, Detail_4]
};

export const DetailsBlockData: Types.DetailsBlock = {
  _type: 'detailsBlock',
  _id: 'detailsBlock_1',
  darkMode: false,
  details: [Details_1, Details_2]
};
