import React, { FC, useRef, useEffect, useCallback } from 'react';
import { Image } from 'components/base';
import { ButtonV2 } from 'components/base/Button/buttonV2';
import { getSrcSet } from 'utils/getSrcSet';
import { useMatchMedia } from 'hooks/useMatchMedia';

import cx from 'classnames';
import contentfulImgUtil from 'utils/contentfulImgUtil';

import * as Types from '../../lib/types';

type TabImageProps = {
  id: string;
  isActive: boolean;
  image?: Types.Image | null;
  onActive: (ref: React.RefObject<HTMLDivElement>) => void;
  index: number;
  setActiveTab: (index: number) => void;
};

const TabImage: FC<TabImageProps> = ({
  id,
  image,
  isActive,
  onActive,
  index,
  setActiveTab
}) => {
  const tabImageRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive) {
      onActive(tabImageRef);
    }
  }, [onActive, isActive]);

  if (!image) return null;

  return (
    <div
      ref={tabImageRef}
      id={id}
      className={cx(
        isActive ? `tab-image-wrapper__active` : `tab-image-wrapper__inactive`,
        'tab-image-wrapper'
      )}
      onClick={() => setActiveTab(index)}
    >
      <Image
        src={contentfulImgUtil(image.url)}
        sizes="(max-width: 375px) 58vw, (max-width: 600px) 43vw, (max-width: 749px) 49vw, 20vw"
        srcSet={getSrcSet(image.url)}
        alt={image.alt}
        className="tab-image"
      />
    </div>
  );
};

type TabItem = {
  image?: Types.Image;
  body?: string;
  title: string;
  key: string;
  ctas?: Types.ButtonLink[];
};

type TabsProps = {
  /**
   * Layout A: traditional tabs with images above the content
   * Layout B: scrolls images horizontally based on active tab
   */
  layout: 'layout-a' | 'layout-b';
  items: TabItem[];
  activeTab: number;
  setActiveTab: (index: number) => void;
  cta?: Types.ButtonLink;
  className?: string;
};

const Tabs: FC<TabsProps> = ({
  layout,
  items,
  activeTab,
  setActiveTab,
  cta,
  className
}) => {
  const tabContainterRef = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const handleActiveChange = useCallback(
    (ref: React.RefObject<HTMLDivElement>) => {
      if (!ref.current) return;
      if (!imageContainerRef.current) return;
      const newLeft = -ref.current.offsetLeft;
      imageContainerRef.current.style.left = newLeft + 'px';
    },
    []
  );

  const isMobile = useMatchMedia('(max-width: 749px)');

  return (
    <div ref={tabContainterRef} className={cx('tab-wrapper', className)}>
      {/* Empty div to keep content centered */}
      <div className="empty-container" aria-label="" />

      {/* Tab Image Container (layout a) */}
      {layout === 'layout-a' && (
        <div className="tab-image-container-layout-a">
          {items.map((item, index) => {
            if (!item.image) return null;
            const hasImage = Boolean(item.image);

            return (
              <div
                key={item.key}
                id={`tab-content-${item.key}`}
                role="tabpanel"
                aria-hidden={activeTab !== index}
                className={cx(
                  !hasImage
                    ? 'none'
                    : activeTab === index
                    ? `tab-image-wrapper__active`
                    : `tab-image-wrapper__inactive`,
                  'tab-image-wrapper'
                )}
              >
                {hasImage && (
                  <Image
                    src={contentfulImgUtil(item.image.url)}
                    sizes="(max-width: 749px) 29vw, 28vw"
                    srcSet={getSrcSet(item.image.url)}
                    alt={item.image.alt}
                    className="tab-image"
                  />
                )}
              </div>
            );
          })}
        </div>
      )}

      {/* Tab Image Container (layout b) */}
      {layout === 'layout-b' && (
        <div className="tab-image-wrapper-layout-b">
          <div ref={imageContainerRef} className="tab-image-container-layout-b">
            {items.map((item, index) => (
              <TabImage
                id={`tab-content-${item.key}`}
                image={item.image}
                key={item.key}
                isActive={activeTab === index}
                onActive={handleActiveChange}
                index={index}
                setActiveTab={setActiveTab}
              />
            ))}
          </div>
        </div>
      )}

      {/* Pagination - only visible on mobile */}
      <ul className="pagination-wrapper" role="tablist">
        {items.map((item, index) => (
          <li
            role="tab"
            aria-selected={activeTab === index}
            aria-controls={`tab-content-${item.key}`}
            key={item.key}
            className="pagination-button"
            onClick={() => {
              setActiveTab(index);
            }}
          >
            <span
              className={cx(
                'pagination-item',
                layout === 'layout-b'
                  ? 'bg-color-medium-gray'
                  : 'bg-color-white',
                activeTab === index
                  ? 'pagination-item__active'
                  : 'pagination-item__inactive'
              )}
            />
          </li>
        ))}
      </ul>

      {/* Tabs */}
      <div className="tabs">
        <ul className="tablist" role={isMobile ? undefined : 'tablist'}>
          {items.map((item, index) => (
            <li
              key={item.key}
              role={isMobile ? 'tabpanel' : 'presentation'}
              aria-hidden={isMobile ? activeTab !== index : undefined}
              className={cx(
                `tab-item`,
                activeTab === index ? `tab-item__active` : `tab-item__inactive`,
                {
                  'text-center': layout === 'layout-b'
                }
              )}
            >
              <button
                id={`tab-header-${item.key}`}
                className="tab-item-title"
                onClick={() => setActiveTab(index)}
                role={isMobile ? undefined : 'tab'}
                aria-controls={isMobile ? undefined : `tab-content-${item.key}`}
                aria-label={
                  activeTab === index
                    ? `Collapse tab ${index}: ${item.title}`
                    : `Expand tab ${index}: ${item.title}`
                }
                aria-selected={isMobile ? undefined : activeTab === index}
                tabIndex={0}
              >
                {item.title}
              </button>

              <div
                id={`tab-content-${item.key}`}
                className={cx(
                  `tab-item-content tab-item-content${
                    activeTab === index ? '__active' : '__inactive'
                  }`
                )}
                aria-hidden={isMobile ? undefined : activeTab !== index}
                role={isMobile ? undefined : 'tabpanel'}
                {...{ inert: index !== activeTab ? '' : undefined }}
                tabIndex={index === activeTab ? 0 : -1}
              >
                {item.body}
              </div>

              {item.ctas && item.ctas.length > 0 && (
                <div className="tab-ctas">
                  {item.ctas.map((cta, index) => (
                    <ButtonV2
                      key={index}
                      // wrapperClassName="tab-item-cta"
                      className="tab-item-cta"
                      buttonType={cta.variant ? cta.variant : 'primary'}
                      to={cta.link.url}
                      ariaLabel={cta.link.ariaLabel}
                    >
                      {cta.label}
                    </ButtonV2>
                  ))}
                </div>
              )}
            </li>
          ))}

          {/* CTA */}
          {cta && (
            <div className="tab-cta-wrapper">
              <ButtonV2
                className="tab-cta"
                to={cta.link.url}
                buttonType={cta.variant ? cta.variant : 'primary'}
                ariaLabel={cta.link.ariaLabel}
              >
                {cta.label}
              </ButtonV2>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Tabs;
