import React, { FC, useState } from 'react';
import { useInView } from 'react-hook-inview';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { SVGMap } from 'svgs';
import { Button, Image, Video } from 'components/base';
import { getSrcSet } from 'utils/getSrcSet';

import cx from 'classnames';
import BackgroundMedia from 'components/BackgroundMedia';
import Modal from 'components/Modal';
import contentfulImgUtil from 'utils/contentfulImgUtil';

import * as Types from '../../lib/types';

type TextBlockProps = {
  textBlock: Types.TextBlock;
};

const TextBlock: FC<TextBlockProps> = ({ textBlock }) => {
  const {
    title,
    darkMode,
    applyScreenBlend,
    backgroundMedia,
    foregroundMedia
  } = textBlock;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const isMobile = useMatchMedia('(max-width: 749px)');

  const [ref, isVisible] = useInView({
    rootMargin: '0px 0px -50% 0px'
  });

  return (
    <div
      ref={ref}
      className={cx('w100 h100', !backgroundMedia && 'bg-color-lightest-gray')}
    >
      {backgroundMedia && (
        <div
          className={cx(
            'background-media',
            isVisible ? 'opacity-1' : 'opacity-0'
          )}
        >
          <BackgroundMedia
            media={
              isMobile && backgroundMedia.mobile
                ? backgroundMedia.mobile
                : backgroundMedia.desktop
            }
            videoWidth="100%"
            videoHeight="100%"
          />
        </div>
      )}
      <div
        className={cx(
          'text-block',
          backgroundMedia ? 'min-vh100' : 'v100',
          applyScreenBlend && 'mix-blend-mode-screen'
        )}
      >
        {!backgroundMedia && <div className="border-top" />}
        <h2 className="content-block-title">{title}</h2>
        {foregroundMedia && (
          <div className="foreground-content">
            <Button
              ariaLabel={`open video modal: ${foregroundMedia.video.caption}`}
              variant="text"
              fontColor={darkMode ? 'white' : 'black'}
              hover={darkMode ? 'white' : 'black'}
              onClick={toggleModal}
            >
              <div className="foreground-media">
                {foregroundMedia.media._type === 'image' ? (
                  <Image
                    src={contentfulImgUtil(foregroundMedia.media.url)}
                    sizes="(max-width: 749px) 240px, 464px"
                    srcSet={getSrcSet(foregroundMedia.media.url)}
                    alt={foregroundMedia.media.alt}
                    className="foreground-image"
                  />
                ) : (
                  <div className="foreground-video">
                    <Video
                      video={foregroundMedia.media}
                      width="100%"
                      autoPlay={true}
                      muted={true}
                      controls={false}
                      loop={true}
                    />
                  </div>
                )}
              </div>
            </Button>
            <div className="foreground-cta">
              <Button
                ariaLabel={`open video modal: ${foregroundMedia.video.caption}`}
                className={cx(
                  'label',
                  darkMode ? 'label-underline-light' : 'label-underline-dark'
                )}
                variant="text"
                fontColor={darkMode ? 'white' : 'black'}
                hover={darkMode ? 'white' : 'black'}
                onClick={toggleModal}
              >
                <div className="play-button">
                  <SVGMap icon="play" />
                </div>
                <p className="ml1 flex-1">{foregroundMedia.video.caption}</p>
              </Button>
            </div>
          </div>
        )}
      </div>
      {/* 
        Content block spacer should only render for blocks
        that have background and foreground media
       */}
      {backgroundMedia && foregroundMedia && (
        <div className="content-block-spacer" />
      )}
      {isModalOpen && foregroundMedia && (
        <div className="fixed t0 l0 vw100 vh100 z-overlay">
          <Modal
            backgroundColor="rgba(34, 34, 34, 0.9)"
            closeIconColor="white"
            onGoBack={toggleModal}
            className="flex justify-center items-center"
            innerWrapperStyleOverrides="modal-inner-wrapper"
            title="Video Modal"
          >
            <Video
              video={foregroundMedia.video}
              className="aspect-landscape md:w80"
              autoPlay={false}
              muted={false}
              controls={true}
              loop={false}
              width="100%"
              height="100%"
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default TextBlock;
