import * as React from 'react';
import cx from 'classnames';
import { useState } from 'react';
import { Status } from 'constants/Status';
import { useShopify } from 'lib/Shopify';
import { BaseCartLine } from 'types/generated-shopify';
import { lineItemIsNaPhone, findSimAndDeviceInsuranceLineItems, lineItemIsDeviceInsurance, findNAPhoneLineItem } from './shared';

interface CartLineItemQuantityProps {
  lineItem: BaseCartLine;
  lineItems: BaseCartLine[];
}

const CartLineItemQuantity: React.FC<CartLineItemQuantityProps> = ({
  lineItem,
  lineItems
}) => {
  const { cartStatus, updateLineItems } = useShopify();
  const [isNAPhone] = useState(lineItemIsNaPhone(lineItem));
  const [isDeviceInsurance] = useState(lineItemIsDeviceInsurance(lineItem));

  const decrementQuantity = () => {
    const linesToUpdate = [
      {
        id: lineItem.id,
        quantity: Math.max(lineItem.quantity - 1, 0)
      }
    ];

    if (isNAPhone) {
      const lineItemsToDecrement = findSimAndDeviceInsuranceLineItems(lineItems);
      lineItemsToDecrement.forEach(lineItem => linesToUpdate.push({
        id: lineItem.id,
        quantity: Math.max(lineItem.quantity - 1, 0)
      }));
    }

    updateLineItems(linesToUpdate);
  };

  const incrementQuantity = () => {
    let quantity = lineItem.quantity + 1;

    if (isDeviceInsurance) {
      const naPhoneLineItem = findNAPhoneLineItem(lineItems);
      const maxCount = naPhoneLineItem?.quantity || 0;
      if (maxCount < quantity) quantity = maxCount;
    }

    const linesToUpdate = [
      {
        id: lineItem.id,
        quantity: quantity
      }
    ];

    if (isNAPhone) {
      const lineItemsToIncrement = findSimAndDeviceInsuranceLineItems(lineItems);
      lineItemsToIncrement.forEach(lineItem => linesToUpdate.push({
        id: lineItem.id,
        quantity: Math.max(lineItem.quantity + 1, 0)
      }));
    }

    updateLineItems(linesToUpdate);
  };

  const isDisabled = cartStatus === Status.PENDING;
  return (
    <div
      className={cx(
        'Cart__lineItemQuantity',
        isDisabled && 'Cart__lineItemQuantity--disabled'
      )}
    >
      <button disabled={isDisabled} type="button" onClick={decrementQuantity}>
        -
      </button>
      <div className="Cart__lineItemQuantity__quantity">
        {lineItem.quantity}
      </div>

      <button disabled={isDisabled} type="button" onClick={incrementQuantity}>
        +
      </button>
    </div>
  );
};

export default CartLineItemQuantity;
