import * as Types from '../../lib/types';

export const TestimonialBlockData: Types.TestimonialBlock = {
  _type: 'testimonialBlock',
  _id: 'testimonialBlock_1',
  darkMode: true,
  backgroundMedia: {
    desktop: {
      _type: 'video',
      url: 'https://videos.ctfassets.net/tbduj203nkkk/3sg8wmr8pltS2kslQpM83f/8c62f99dd57e7618addfe6bbaf41b95d/clouds.mp4',
      alt: 'A video of clouds',
      posterImage: {
        _type: 'image',
        url: 'https://images.ctfassets.net/tbduj203nkkk/1p2wWa4Dzmfh1ZjjF1EtlK/21cc625d75a211f6782f8e87e328ed0a/cloudsPosterImage.png',
        alt: 'A photo of clouds'
      }
    }
  },
  testimonials: [
    {
      _type: 'testimonial',
      quote:
        'I love this phone. It will make you a happier, more present person.'
    },
    {
      _type: 'testimonial',
      quote: 'The Light Phone has probably given me back thousands of hours.'
    },
    {
      _type: 'testimonial',
      quote:
        'It’s like the fog in my head has finally been cleared out and I’m seeing and enjoying the world anew.'
    },
    {
      _type: 'testimonial',
      quote:
        'They said, ‘Because you’re never on it, you’re more fun.’ I can only imagine how many ‘fun Dad things’ I would have missed if it wasn’t for this thing.'
    },
    {
      _type: 'testimonial',
      quote:
        'Everyone in my work and home lives have commented on noticing an appreciable change in mood and productivity.'
    },
    {
      _type: 'testimonial',
      quote:
        'The ‘what ifs’ can be pretty overwhelming when considering the move, but honestly, l’ve not missed anything.'
    },
    {
      _type: 'testimonial',
      quote: 'It’s really refreshing to not be sold something all of the time.'
    },
    {
      _type: 'testimonial',
      quote: 'It’s more than a simple phone, it’s a lifestyle change.'
    }
  ]
};
