import contentfulImgUtil from './contentfulImgUtil';

export const getSrcSet = (url: string) => {
  return `${contentfulImgUtil(url, '240')} 240w, ${contentfulImgUtil(
    url,
    '640'
  )} 640w, ${contentfulImgUtil(url, '750')} 750w, ${contentfulImgUtil(
    url,
    '828'
  )} 828w, ${contentfulImgUtil(url, '1080')} 1080w, ${contentfulImgUtil(
    url,
    '1200w'
  )} 1200w, ${contentfulImgUtil(url, '1920')} 1920w, ${contentfulImgUtil(
    url,
    '2048'
  )} 2048w, ${contentfulImgUtil(url, '3840')} 3840w,`;
};
