import { Collection, Product } from 'types/generated-shopify';
import {
  FETCH_COLLECTIONS_FULFILLED,
  FETCH_PHONE_FLOW_PRODUCTS_FULFILLED,
  FETCH_PRODUCT_FULFILLED
} from '../actions/shopifyCatalogueActions';

/**
 * State
 */

export type PhoneFlowProducts = {
  lightPhoneLight: Product;
  lightPhoneDark: Product;
  lightPhoneIII: Product;
  screenProtector?: Product;
  protectiveCaseBlack?: Product;
  protectiveCaseLight?: Product;
  carbonOffset?: Product;
  lightUsSim: Product;
  deviceInsurance?: Product;
};

export interface ShopifyCatalogueState {
  collections: Collection[];
  productsByHandle: Record<string, Product>;
  phoneFlowProducts: PhoneFlowProducts | null;
}

const initialState: ShopifyCatalogueState = {
  collections: [],
  productsByHandle: {},
  phoneFlowProducts: null
};

interface FetchCollectionsAction {
  type: typeof FETCH_COLLECTIONS_FULFILLED;
  payload: Collection[];
}

interface FetchProductsAction {
  type: typeof FETCH_PRODUCT_FULFILLED;
  payload: Product;
}

interface FetchPhoneFlowProductsAction {
  type: typeof FETCH_PHONE_FLOW_PRODUCTS_FULFILLED;
  payload: PhoneFlowProducts;
}

type CatalogueAction =
  | FetchCollectionsAction
  | FetchProductsAction
  | FetchPhoneFlowProductsAction;

const catalogueReducer = (
  state: ShopifyCatalogueState = initialState,
  action: CatalogueAction
): ShopifyCatalogueState => {
  switch (action.type) {
    case FETCH_COLLECTIONS_FULFILLED:
      const collections = action.payload;
      return {
        ...state,
        collections
      };
    case FETCH_PRODUCT_FULFILLED:
      const product = action.payload;
      return {
        ...state,
        productsByHandle: {
          [product.handle]: product
        }
      };
    case FETCH_PHONE_FLOW_PRODUCTS_FULFILLED:
      const phoneFlowProducts = action.payload;
      return {
        ...state,
        phoneFlowProducts
      };

    default:
      return state;
  }
};

export default catalogueReducer;
