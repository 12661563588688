import { BaseCartLine } from 'types/generated-shopify';

/**
 * Shared functions
 */

export const lineItemIsNaPhone = (lineItem: BaseCartLine): boolean => {
  return (
    lineItem.merchandise.title === 'North America' &&
    lineItem.merchandise.product.handle.startsWith('light-phone-ii-')
  );
};

export const lineItemIsDeviceInsurance = (lineItem: BaseCartLine): boolean => {
  return lineItem.merchandise.product.handle === "light-protect-plan";
};

export const lineItemIsUSSim = (lineItem: BaseCartLine): boolean => {
  return lineItem.merchandise.product.handle === "light-us-sim-card";
};

export const findSimLineItem = (
  lineItems: BaseCartLine[]
): BaseCartLine | undefined => {
  return lineItems.find((lineItem) => lineItemIsUSSim(lineItem));
};

export const findSimAndDeviceInsuranceLineItems = (
  lineItems: BaseCartLine[]
): BaseCartLine[] | [] => {
  return lineItems.filter((lineItem) => {
    return lineItemIsUSSim(lineItem) || lineItemIsDeviceInsurance(lineItem);
  });
};

export const findNAPhoneLineItem = (
  lineItems: BaseCartLine[]
): BaseCartLine | undefined => {
  return lineItems.find((lineItem) => lineItemIsNaPhone(lineItem));
};